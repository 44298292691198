import React, { Component, Fragment, useEffect, useState } from "react"
import { Formik } from "formik"
import axios from "axios"
import ru from "react-phone-input-2/lang/ru.json"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./style.sass"
import Select from "react-select"
import { PERIODS } from "../../helpers/constants.ts"
import Logo from "../../../static/images/svg/logo-new.svg"
import { Link } from "gatsby"

const formatOptionLabel = ({ value, label, price }) => (
  <div className="office-form-options">
    <div className="office-form-options__label">{label}</div>
    <div className="office-form-options__price">{price && price + " р"}</div>
  </div>
)
const windowGlobal = typeof window !== "undefined" && window

const FormCoworking = props => {
  const [period, setPeriod] = useState("")
  const [promo, setPromo] = useState(null)
  const isGuestInitial =
    (props.location &&
      (() => {
        const pathname = props.location.pathname || ""

        const params = new URLSearchParams(
          new URL(props.location, "http://dummy.com").search
        )
        return (
          params.get("for") === "guest" ||
          pathname.includes("/payment-cowork?for=guest")
        )
      })) ||
    false
  const [isGuest, setGuest] = useState(isGuestInitial)
  const [invoiceId, setInvoiceId] = useState("")
  const [successMessage, setSuccessMessage] = useState(false)
  const [canPay, setCanPay] = useState(true)
  const [isDisabled, setDisabled] = useState(false)
  const periodPromo = promo
    ? PERIODS.filter(option => option.promo === promo)[0]
    : period
  const defaultPricePromo = promo
    ? PERIODS.filter(option => option.promo === promo)[0].price
    : ""

  const initialValues = {
    name: "",
    surname: "",
    lastname: "",
    phone: "",
    company: "",
    inn: "",
    email: "",
    status: new URLSearchParams(props.location.search).get("for") || "guest",
    period: periodPromo,
    price: defaultPricePromo,
  }

  useEffect(() => {
    const search = props.location.search
    const promoId = new URLSearchParams(search).get("promo")

    const promoIdArr = PERIODS.filter(option => option.promo)
    search &&
      promoIdArr.some(item => item.promo === promoId) &&
      setPromo(promoId)
  }, [props.location.search])

  const changeOptionsValue = type => {
    if (promo) {
      return PERIODS.filter(option => option.promo === promo)
    } else {
      return PERIODS.filter(option => option.type === type)
    }
  }

  const changePeriod = props => {
    if (promo) {
      const promoArr = PERIODS.filter(option => option.promo === promo)
      setPeriod(promoArr[0])
      return promoArr[0]
    } else {
      return period
    }
  }

  const onSubmit = data => {
    const formType = localStorage.getItem("tarif") || "coworking"
    setDisabled(true)
    if (!promo) {
      if (data.status === "guest") {
        window.ga("send", "event", {
          eventCategory: "Coworking_guest_send",
          eventAction: "send_form",
        })
      } else if (data.status === "participant") {
        window.ga("send", "event", {
          eventCategory: "Coworking_send",
          eventAction: "send_member",
        })
      } else if (data.status === "participant-legal") {
        window.ga("send", "event", {
          eventCategory: "Coworking_only_send",
          eventAction: "form_сoworking",
        })
      }
    }

    axios
      .post(`https://billing.sk.w6p.ru/api/v1/orders`, {
        total_order_cost: data.price,
        roistat_id: "",
        payment_method: "card",
        source: "coworking",
        formGroup: formType,
        company: {
          contact_name: data.name,
          contact_surname: data.surname,
          contact_lastname: data.lastname,
          inn: data.inn,
          email: data.email.toLowerCase(),
          tel: data.phone,
          name: data.company,
        },
        products: [
          {
            name: data.status,
            duration: data.period,
            person_in_group: 1,
            extended_excursion: 1,
            ticket_price: data.price,
          },
        ],
      })
      .then(async res => {
        if (res.data.data) {
          await setInvoiceId(res.data.data.id)
          if (promo) {
            window.ym && window.ym(62628037, "reachGoal", "stock")

            window.ga("send", "event", {
              eventCategory: "discount_pay",
              eventAction: "pay",
            })
          } else {
            window.ym && window.ym(62628037, "reachGoal", "rate_cowork")

            if (data.status === "guest") {
              window.ga("send", "event", {
                eventCategory: "Coworking_pay",
                eventAction: "pay",
              })
            }

            if (data.status === "participant") {
              window.ga("send", "event", {
                eventCategory: "Coworking_pay_member",
                eventAction: "pay",
              })
            } else if (data.status === "participant-legal") {
              window.ym && window.ym(62628037, "reachGoal", "participant_legal")
            }
          }

          canPay ? payCard(data, res.data.data.id) : setSuccessMessage(true)
        }
      })
  }

  const payCard = (formData, orderId) => {
    const cpWidget = windowGlobal.cp
    let data = {}
    data.cloudPayments = {
      customerReceipt: {
        Items: [
          {
            label: `Coworking - ${formData.period}`,
            price: parseInt(formData.price).toFixed(2),
            quantity: 1,
            vat: 20,
            amount: parseInt(formData.price).toFixed(2),
          },
        ],
        email: formData.email.toLowerCase(),
        phone: formData.phone,
        amounts: {
          electronic: parseInt(formData.price).toFixed(2),
        },
      },
    }
    var widget = new cpWidget.CloudPayments()

    widget.pay(
      "charge", // или 'charge'
      {
        // options
        publicId: "pk_a64d78cecc96a7e4fa68667367c7a", //id из личного кабинета
        description: `Coworking - ${formData.period}`, //назначение
        amount: formData.price, //сумма
        currency: "RUB", //валюта
        invoiceId: orderId, //номер заказа  (необязательно)
        accountId: formData.email.toLowerCase(), //идентификатор плательщика (необязательно)
        skin: "modern", //дизайн виджета
        data: data,
      },
      {
        onSuccess: function(options) {
          // success
          //действие при успешной оплате И ЗАКРЫТИИ ОКНА ВИДЖЕТА
        },
        onFail: function(reason, options) {
          // fail
          //действие при неуспешной оплате
          // fail
          axios
            .post(
              `https://billing.sk.w6p.ru/api/v1/change-status-cloud-payment`,
              {
                status: "ErrorPayment",
                InvoiceId: orderId,
              }
            )
            .then(res => {
              console.log(res)
              setDisabled(false)
            })
            .catch(e => {
              console.log(e)
              setDisabled(false)
            })
        },
        onComplete: function(paymentResult, options) {
          if (paymentResult.success) {
            // success
            axios
              .post(
                `https://billing.sk.w6p.ru/api/v1/change-status-cloud-payment`,
                {
                  status: "paid",
                  InvoiceId: orderId,
                  formGroup: "coworking",
                }
              )
              .then(res => {
                setSuccessMessage(true)
                setDisabled(false)
              })
              .catch(e => {
                console.log(e)
                setDisabled(false)
              })
          } else {
            // fail
            axios
              .post(
                `https://billing.sk.w6p.ru/api/v1/change-status-cloud-payment`,
                {
                  status: "ErrorPayment",
                  InvoiceId: orderId,
                }
              )
              .then(res => {
                console.log(res)
                setDisabled(false)
              })
              .catch(e => {
                console.log(e)
                setDisabled(false)
              })
          }
        },
      }
    )
  }

  const changeStatus = (props, payment, status) => {
    setCanPay(payment)
    setPeriod("")
    props.setFieldValue("status", status)
    if (status === "participant-legal") {
      handleChangePeriod(
        {
          value: "",
          label: "",
          price: "",
          type: "participant-legal",
        },
        props.setFieldValue
      )
    }
  }

  const validate = values => {
    let errors = {}

    if (!values.name) {
      errors.name = "Поле не может быть пустым"
    }

    if (!values.surname) {
      errors.surname = "Поле не может быть пустым"
    }

    if (!values.phone) {
      errors.phone = "Поле не может быть пустым"
    } else if (!/(\+?\d[- .]*){7,13}/i.test(values.phone)) {
      errors.phone = "Введен некорректный номер телефона"
    }
    if (!values.email) {
      errors.email = "Поле не может быть пустым"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(values.email)
    ) {
      errors.email = "Введен некорректный Email"
    }
    if (promo) {
      values.price = +defaultPricePromo
      values.period = periodPromo.value
    }

    if (!values.period && values.status !== "participant-legal") {
      errors.period = "Поле не может быть пустым"
    }

    if (values.status !== "guest" && !values.inn) {
      errors.inn = "Поле не может быть пустым"
    }

    return errors
  }

  const handleChangePeriod = (selectedOption, setFieldValue) => {
    setFieldValue("period", selectedOption.value)
    setFieldValue("price", +selectedOption.price)
    setPeriod(selectedOption)
  }

  return (
    <Fragment>
      <div className="smart-office">
        <div className="office-form__title">
          {canPay && !successMessage
            ? "Оплата коворкинга"
            : !canPay && successMessage
            ? "Спасибо за заявку!"
            : "Заявка на коворкинг"}
        </div>
        <div className="smart-office__grid">
          <div className="smart-office__col">
            <div
              className={`office-form__wr ${successMessage ? "success" : ""} `}
            >
              {successMessage ? (
                <div className="order-page-desc__title">
                  {canPay && (
                    <p>
                      На вашу электронную почту прийдет счет, а также
                      дополнительная информация по заказу.
                    </p>
                  )}
                  <p>
                    Менеджер свяжется с вами в ближайшее время для уточнения
                    информации по бронированию.
                  </p>
                </div>
              ) : (
                <Formik
                  initialValues={initialValues}
                  validate={validate}
                  validateOnChange={false}
                  validateOnBlur
                  onSubmit={(values, actions) => {
                    actions.setSubmitting(false)
                    onSubmit(values)
                  }}
                >
                  {props => (
                    <form onSubmit={props.handleSubmit} className="office-form">
                      <div className="office-form__fields-title">
                        Данные клиента
                      </div>
                      <div className="office-form__field">
                        <div className="office-form__label">Фамилия</div>
                        <div className="office-form__input">
                          <input
                            type="text"
                            value={props.values.surname}
                            name="surname"
                            onChange={props.handleChange}
                          />
                          {props.errors.surname && props.touched.surname ? (
                            <span className="help-block error">
                              {props.errors.surname}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="office-form__field">
                        <div className="office-form__label">Имя</div>
                        <div className="office-form__input">
                          <input
                            type="text"
                            value={props.values.name}
                            name="name"
                            onChange={props.handleChange}
                          />
                          {props.errors.name && props.touched.name ? (
                            <span className="help-block error">
                              {props.errors.name}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="office-form__field">
                        <div className="office-form__label">Отчество</div>
                        <div className="office-form__input">
                          <input
                            type="text"
                            value={props.values.lastname}
                            name="lastname"
                            onChange={props.handleChange}
                          />
                          {props.errors.lastname && props.touched.lastname ? (
                            <span className="help-block error">
                              {props.errors.lastname}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="office-form__field">
                        <div className="office-form__label">Компания</div>
                        <div className="office-form__input">
                          <input
                            type="text"
                            value={props.values.company}
                            name="company"
                            onChange={props.handleChange}
                          />
                          {props.errors.company && props.touched.company ? (
                            <span className="help-block error">
                              {props.errors.company}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="office-form__field">
                        <div className="office-form__label">ИНН</div>
                        <div className="office-form__input">
                          <input
                            type="number"
                            value={props.values.inn}
                            name="inn"
                            onChange={props.handleChange}
                          />
                          {props.errors.inn && props.touched.inn ? (
                            <span className="help-block error">
                              {props.errors.inn}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="office-form__field">
                        <div className="office-form__label">Телефон</div>
                        <div className="office-form__input">
                          <PhoneInput
                            country={"ru"}
                            enableAreaCodes={true}
                            value={props.values.phone}
                            localization={ru}
                            onChange={value =>
                              props.setFieldValue("phone", value)
                            }
                            enableSearch={true}
                            searchPlaceholder="Поиск"
                            placeholder="(999) 999-99-99"
                          />
                          {props.errors.phone && props.touched.phone ? (
                            <span className="help-block error">
                              {props.errors.phone}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="office-form__field">
                        <div className="office-form__label">E-mail</div>
                        <div className="office-form__input">
                          <input
                            name="email"
                            type="email"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.email}
                          />
                          {props.errors.email && props.touched.email ? (
                            <span className="help-block error">
                              {props.errors.email}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="office-form__fields-title">
                        Информация о тарифе
                      </div>

                      <div className="office-form__field">
                        <div className="office-form__label">Статус</div>
                        {isGuest || promo ? (
                          <div className="office-form__input">
                            <div className="form__label form__radios">
                              <label className="form__radio">
                                <input
                                  type="radio"
                                  name="status"
                                  value={"guest"}
                                  checked={props.values.status === "guest"}
                                  onChange={() => {
                                    changeStatus(props, true, "guest")
                                  }}
                                />
                                <i />
                                <span className="form__radio-name">
                                  Гость Технопарка (без юр. адреса)
                                </span>
                              </label>
                            </div>
                          </div>
                        ) : (
                          <div className="office-form__input">
                            <div className="form__label form__radios">
                              <label className="form__radio">
                                <input
                                  type="radio"
                                  name="status"
                                  value={"participant"}
                                  checked={
                                    props.values.status === "participant"
                                  }
                                  onChange={() => {
                                    changeStatus(props, true, "participant")
                                  }}
                                />
                                <i />
                                <span className="form__radio-name">
                                  Участник «Сколково» (без юр. адреса)
                                </span>
                              </label>
                            </div>
                            <div className="form__label form__radios">
                              <label className="form__radio">
                                <input
                                  type="radio"
                                  name="status"
                                  value={"participant-legal"}
                                  checked={
                                    props.values.status === "participant-legal"
                                  }
                                  onChange={() => {
                                    changeStatus(
                                      props,
                                      false,
                                      "participant-legal"
                                    )
                                  }}
                                />
                                <i />
                                <span className="form__radio-name">
                                  Участник «Сколково» (с юр. адресом)
                                </span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      {props.values.status !== "participant-legal" && (
                        <div className="office-form__field">
                          <div className="office-form__label">Срок</div>
                          <div className="office-form__input">
                            <Select
                              className="period-select"
                              classNamePrefix="select"
                              value={changePeriod()}
                              onChange={selectedOption =>
                                handleChangePeriod(
                                  selectedOption,
                                  props.setFieldValue
                                )
                              }
                              options={changeOptionsValue(props.values.status)}
                              name="period"
                              placeholder="Выберите из списка"
                              isSearchable={false}
                              formatOptionLabel={formatOptionLabel}
                            />
                            {props.errors.period && props.touched.period ? (
                              <span className="help-block error">
                                {props.errors.period}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      )}

                      {period.price && (
                        <div className="office-form__field">
                          <div className="office-form__label">
                            <div className="office-form__price-title">
                              Стоимость:
                            </div>
                          </div>
                          <div className="office-form__input">
                            <div className="office-form__price">
                              {(+period.price).toLocaleString("ru")} руб
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="form__text">
                        <span>
                          Нажимая кнопку Отправить, я&nbsp;выражаю согласие
                          на&nbsp;
                        </span>
                        <a
                          href="https://docs.sk.ru/category/passport/personal"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>обработку персональных данных</span>
                        </a>
                      </div>
                      <button
                        className="button"
                        type="submit"
                        disabled={isDisabled}
                      >
                        <span>{canPay ? "Оплатить" : "Оставить заявку"}</span>
                      </button>
                      <div className="office-form__oferta">
                        <a
                          href="/Договор-оферта_коворкинг.pdf"
                          target="_blank"
                          download
                          rel="noopener noreferrer"
                        >
                          Договор оферта
                        </a>
                        <br />
                        <a
                          href="https://coworking.sk.ru/regulations"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Правила предоставления коворкинг-услуг
                        </a>
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </div>
          </div>
          <div className="smart-office__col">
            <div className="smart-office__img">
              <img src="/images/payment2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormCoworking
